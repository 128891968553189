import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  buttonLabel?: string
  description?: string
  image?: ImageProps
  title?: string
  URL: string
  weight?: number
}

export const Item = memo(function Item({
  buttonLabel,
  description,
  image,
  title,
  URL,
}: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      {image ? <Image {...image} /> : null}
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {buttonLabel ? (
          <CTA className="visual-cta" label={buttonLabel} noActions />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 33%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
    .visual-cta {
      &:after {
        top: 0;
      }
    }
  }
  &:first-of-type {
    transform: translateY(3.75rem);
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    backface-visibility: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 0;
    &:first-of-type {
      transform: none;
    }
  }
`
const Wrapper = styled.div`
  width: 100%;
  padding: 0 3.75rem 3.75rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 0 1.875rem 1.875rem;
  }

  @media (max-width: 1023px) {
    position: relative;
    bottom: auto;
    left: auto;
    padding: 3.75rem 8.454vw;
    text-align: center;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  max-width: 410px;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 0.75rem;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const CTA = styled(Button)`
  margin-top: 1.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.125rem;
  }
`
